import React, { useEffect } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { changeLocale, injectIntl, useIntl } from "gatsby-plugin-intl";
import { Contentful_Article } from "graphql-types";
import { ContentTypeColour, ThemeContext, Type } from "../contexts/ThemeContext";
import Layout from "../layouts/layout";
import Article from "../components/article";
import contentfulImageHelper from "../utils/contentful-image-helper";
import { setLocation } from "../utils/geo-location";
import { LocationProvider } from "../contexts/LocationContext";
import Cookies from "js-cookie";

const ArticleEntryTemplate = ({ data }: { data: any }) => {
  const { defaultLocale } = useIntl();

  useEffect(() => {
    const currentLocation = Cookies.get("location");
    if (!currentLocation) {
      setLocation(defaultLocale).then(() => {
        changeLocale(defaultLocale);
      });
    }
  }, []);

  const articleData: Contentful_Article = get(data, "contentful.article");

  return (
    <ThemeContext.Provider value={Type(articleData.contentType?.name ?? "")}>
      <LocationProvider>
        <Layout
          headSnippet={articleData.headSnippet}
          title={articleData.title}
          metaDescription={articleData.metaDescription}
          metaImageUrl={contentfulImageHelper(articleData.shareImage).getShareUrl()}
          hasHero={Type(articleData.contentType?.name ?? "") === ContentTypeColour.Podcasts}
        >
          <main>
            <Article article={articleData} />
          </main>
        </Layout>
      </LocationProvider>
    </ThemeContext.Provider>
  );
};

export default injectIntl(ArticleEntryTemplate);

export const articleQuery = graphql`
  query ArticleById($id: String!, $preview: Boolean!) {
    contentful {
      article(id: $id, preview: $preview) {
        ...articleFields
      }
    }
  }
`;
